@import '~bootstrap/scss/bootstrap';

//Custom
section.error {
    h1 {
        font-size: 4rem;
        font-weight: 700;

        @media (max-width: 1400px) {
            font-size: 3.5rem;
        }
        @media (max-width: 500px) {
            font-size: 2.5rem;
        }
    }
}

a.btn.custom {
    padding-left: 0.20rem;

    span {
        &.spinner-border.hover {
            --bs-spinner-animation-speed: 0s !important;
            width: 0 !important;
            opacity: 0 !important;
            transition: all .2s ease-in-out;
        }
    }

    &:is(&:hover,&:focus) {
        padding-left: 0.75rem;

        span.spinner-border {
            --bs-spinner-animation-speed: 1s !important;
            width: 16px !important;
            opacity: 1 !important;
        }
    }
}

section.handler.r-404 {
    max-height: 100vh; overflow: hidden;
   .img--bg {
       position: absolute;
       left: -10%; top: -5vh;
       height: 110vh;
       opacity: .05;
       z-index: -1;
   }

    h2 {
        color: #13287D;
        font-size: 4.5rem;
        font-weight: 700;
    }
    p {
        color: #616161;
    }
    a.btn {
        border-radius: 999px !important;
        background: #3977BC !important;
        padding: 12px 35px !important;
        color: #fff !important;
        transition: all .2s ease-in-out;
        i { transition: all .2s ease-in-out; }
        &:hover {
            i {
                padding-left: 20px;
            }
        }
    }
    @media (max-width: 991px) {
        .rm-lg {
            display: none !important;
        }
    }
}